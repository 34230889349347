import React, { useState, useRef } from 'react';

const Input = ({ className, name, label, error, value, onChange, onBlur, type = 'text', ...props }) => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef()

  return (
    <div className={`${className} ${error ? '' : 'mb-[32px]'}`}>
      <div className="relative">
        <span
          className={`pointer-events-none transition-all absolute px-2 text-[#A6A8AB] left-8 -translate-y-2/4 ${focus || !!value ? 'top-0 bg-white text-[14px]' : 'top-[50%] text-[16px]'}`}
        >
        {label}
      </span>
        <input
          ref={inputRef}
          {...props}
          className={`border w-full py-6 px-8 rounded-[8px] text-[14px] focus-visible:outline-0 ${!!error ? 'border-[red]' : ''}`}
          type={type}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
            onBlur && onBlur();
          }}
          value={value}
          name={name}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      </div>
      {error && (
        <p
          className="text-[red] text-[12px] m-0 leading-none min-h-[32px] py-2"
        >
          {error}
        </p>
      )}
    </div>
  )
};

export default Input;