import React from "react";
import { Link } from "gatsby";
import LogoIcon from "@components/svg/LogoIcon";

const Footer = () => {
  return (
    <div className="absolute bottom-0">
      <div className="border-b-[50px] border-b-[#221B3C] border-r-[100vw] border-r-[transparent]" />
      <div className="bg-[#221B3C] px-[20px] md:px-[40px] lg:px-[200px] py-[40px] ">
        <div className="flex justify-between items-center">
          <div className="w-[100px]">
            <LogoIcon width={200} />
          </div>
          <button
            className="flex items-center"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <span className="mr-4 text-[12px] text-white">
              BACK TO TOP
            </span>
            <img
              className="w-[32px]"
              src="/img/back_to_top_icon.png"
              alt="back to top"
            />
          </button>
        </div>
        <div className="flex mt-8 justify-center md:justify-start">
          <a href="https://www.linkedin.com/company/the-digital-ba">
            <img
              className="mr-8"
              src="/img/linkedin_icon.png"
              alt="Linkedin"
            />
          </a>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center text-white pt-16">
          <div className="text-[12px] hidden md:block">
            © Copyright 2022 the digital BA
          </div>
          <div>
            <div className="flex flex-col md:flex-row md:justify-end">
              <p className="text-[12px] leading-normal">
                <Link to="/pnp">Privacy Policy</Link>
              </p>
              <p className="text-[12px] leading-normal md:ml-8">
                <Link to="/tnc">Terms & Conditions</Link>
              </p>
              <p className="text-[12px] leading-normal md:ml-8">
                <Link to="/legal">Legal</Link>
              </p>
            </div>
          </div>
          <div className="text-[12px] md:hidden">
            <p className="text-[12px] leading-normal">
              © Copyright 2022 the digital BA
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
