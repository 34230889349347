import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import SEO from "@utils/seo";
import LandingPageTemplate from './Landing';

const LandingPage = ({ data }) => {
  const landingData = data?.markdownRemark?.frontmatter;

  useEffect(() => {
    TagManager.initialize({ gtmId: landingData?.gtmId });
  }, []);

  return (
    <div className="bg-[#edf0f5] relative">
      <SEO {...landingData?.seo} />

      <LandingPageTemplate data={landingData} />
    </div>
  )
}

export default LandingPage;

export const landingPageQuery = graphql`
  query LandingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Landing" } }) {
      frontmatter {
        gtmId
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          price
          yearPrice
          features
          form {
            title
            description
            button
            successTitle
            successDesc
          }
        }
        section2 {
          title
          pinkCone {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blueCone {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cards {
            icon {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            content
          }
          experience {
            description
            customers {
             logo {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              } 
            }
          }
        }
        section3 {
          comments {
            writer
            content
          }
        }
        section4 {
          title
          description
          button
        }
      }
    }
  }
`;