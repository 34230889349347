import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const Section3 = ({ info }) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveSlide(key);
  };

  return (
    <div>
      <div className="border-b-[50px] border-b-[#221B3C] border-l-[100vw] border-l-[transparent]" />
      <div className="bg-[#221B3C] py-[60px] flex justify-center">
        <div className="w-[300px] md:w-[500px]">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            slidesPerGroup={1}
            loop
            loopFillGroupWithBlank
            pagination={false}
            navigation={false}
            onSwiper={updateSwiper}
          >
            {info.comments.map(({ writer, content }, index) => (
              <SwiperSlide key={index}>
                <div className="w-[300px] md:w-[500px] pb-20">
                  <img
                    className="w-[32px] md:w-[48px] mb-8"
                    src="/img/quote_icon.png"
                    alt="quote"
                  />
                  <p className="text-white text-[16px] font-bold leading-normal m-0">
                    {content}
                  </p>
                  <p className="text-white text-[10px] leading-normal m-0 mt-2">
                    {writer}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="text-center">
            {info.comments.map(({ writer, content }, index) => {
              if (index === activeSlide) {
                return (
                  <button
                    className="inline-block p-[5px] bg-[#ffffff] rounded-full mr-2 transition-all"
                    onClick={() => handleChangeActiveKey(index)}
                  />
                );
              }
              return (
                <button
                  className="inline-block p-[4px] bg-[#004CFF] rounded-full mr-2 transition-all"
                  onClick={() => handleChangeActiveKey(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
