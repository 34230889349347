import React from 'react';

const Section4 = ({ info, onButtonClick }) => {
  return (
    <div className="text-center px-[20px] md:px-[40px]">
      <p className="font-bold text-[32px]">{info.title}</p>
      <div className="mb-8">
        <p className="md:w-[500px] text-[14px] leading-normal inline-block">{info.description}</p>
      </div>
      <button
        className="bg-[#004CFF] hover:bg-[#004CFF]/90 px-[20px] py-4 text-white rounded-md text-[18px]"
        onClick={() => onButtonClick()}
      >
        {info.button}
      </button>
    </div>
  )
};

export default Section4;