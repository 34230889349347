import React, { useState } from "react";
import { Link } from "gatsby";
import Image from "@components/Image";
import LogoIcon from "@components/svg/LogoIcon";
import ConsultForm from "./componens/ConsultForm";
import Section2 from "./componens/Section2";
import Section3 from "./componens/Section3";
import Section4 from "./componens/Section4";
import Footer from "./componens/Footer";

import "./landing.scss";

const LandingPageTemplate = ({ data }) => {
  const [formFocus, setFormFocus] = useState(false);

  return (
    <div className="bg-[#edf0f5] relative">
      <div className="absolute z-[99] py-4 lg:py-12 px-[40px] lg:px-[240px]">
        <Link to="/">
          <LogoIcon />
        </Link>
      </div>
      <div className="relative">
        <div className="absolute inset-0 bottom-[30px] z-0">
          <Image className="h-full" data={data?.header?.image} />
        </div>
        <div className="relative grid grid-cols-1 md:grid-cols-2 gap-2 px-[20px] md:px-[40px] lg:px-[240px]">
          <div className="text-white flex flex-col justify-center mt-[100px] md:mt-0">
            <p className="text-[red] font-medium">
              {data?.header?.title}
            </p>
            <div className="relative">
              <span className="absolute top-[0.8rem]">$</span>
              <span className="text-[60px] font-bold pl-[1rem]">
                {data?.header?.price}
              </span>
              <span>/month</span>
            </div>
            <p className="text-[14px] opacity-80">
              {data?.header?.yearPrice}
            </p>
            <div>
              {data?.header?.features.map((feature) => (
                <div className="flex items-center">
                  <div>
                    <div className="w-[12px] h-[12px] rounded-full bg-[#004CFF]" />
                  </div>
                  <p className="ml-4 text-[1.5rem] lg:text-[1.8rem]">
                    {feature}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end pt-[40px] md:pt-[100px]">
            <div className="lg:w-[80%]">
              <ConsultForm
                info={data?.header?.form}
                className="shadow"
                focus={formFocus}
                onBlur={() => setFormFocus(false)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <Section2 info={data.section2} />
      </div>
      <div className="mt-24">
        <Section3 info={data.section3} />
      </div>
      <div className="mt-24">
        <Section4
          info={data.section4}
          onButtonClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
              setFormFocus(true);
            }, 1000);
          }}
        />
      </div>
      <div className="mt-24">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPageTemplate;
