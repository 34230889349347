import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import validator from 'validator';
import Input from './Input';

const ConsultForm = ({ info, onBlur, focus = false }) => {
  const [params, setParams] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
  });
  const [showValidate, setShowValidate] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const validateEmail = () => {
    if (!params.email) {
      return 'Email must not be empty.'
    }
    if (!validator.isEmail(params.email)) {
      return 'Invalid email format.'
    }
    return null
  }

  const validatePhone = () => {
    if (!params.phone) {
      return 'Phone must not be empty.'
    }

    for (let i = 0; i < params.phone.length; i ++) {
      if ((params.phone[i] === '+' && i > 0) || !"+0123456789".includes(params.phone[i])) {
        return 'Invalid phone number format.'
      }
    }

    return null
  }

  const handleSubmit = () => {
    setShowValidate(true);

    if (!params.name || !params.email || !params.phone) {
      return
    }

    if (!validator.isEmail(params.email)) {
      return
    }

    // call api
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "landing_consult", ...params })
    })
      .then(() => setShowSuccessModal(true))
      .catch(error => alert(error));
  }

  return (
    <div>
      <div className="shadow p-16 bg-white rounded-[12px]">
        <p className="text-[20px] font-bold text-center">{info.title}</p>
        <p className="text-[14px] leading-normal text-center">{info.description}</p>

        <form
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="landing_consult"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit()
          }}
        >
          <div className="mt-8">
            <Input
              key={`input-name-${focus}`}
              autoFocus={focus}
              onBlur={() => {
                onBlur && onBlur();
              }}
              name="name"
              value={params.name}
              label="Full Name *"
              onChange={(v) => setParams({ ...params, name: v })}
              error={showValidate && !params.name ? `Full name must not be empty.` : null}
            />
            <Input
              name="email"
              value={params.email}
              label="Email Address *"
              onChange={(v) => setParams({ ...params, email: v })}
              error={showValidate ? validateEmail() : null}
            />
            <Input
              name="phone"
              value={params.phone}
              label="Mobile number *"
              onChange={(v) => {
                setParams({ ...params, phone: v })
              }}
              error={showValidate ? validatePhone() : null}
            />
            <Input
              name="company"
              value={params.company}
              label="Start Up Name"
              onChange={(v) => setParams({ ...params, company: v })}
            />
          </div>

          <button
            className="bg-[#004CFF] hover:bg-[#004CFF]/90 w-full py-4 text-white rounded-md text-[18px]"
            type="submit"
          >
            {info.button}
          </button>
        </form>
      </div>

      {showSuccessModal && (
        <Modal
          isOpen={showSuccessModal}
          onRequestClose={() => setShowSuccessModal(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-40%',
              transform: 'translate(-50%, -50%)',
              border: 'none'
            }
          }}
          overlayClassName="fixed inset-0 bg-[#000000]/80"
        >
          <div className="text-center py-8">
            <div className="inline-block p-6 bg-[#004cff0d] rounded-full">
              <img className="w-[40px] inline-block" src="/img/submit_success_icon.png" alt="success"/>
            </div>
            <p className="text-[#004CFF] font-bold">{info.successTitle}</p>
            <div>
              <p className="text-[12px] w-[60%] inline-block leading-normal">{info.successDesc}</p>
            </div>

            <button
              className="bg-[#004CFF] hover:bg-[#004CFF]/90 w-full py-4 text-white rounded-md text-[18px] mt-4"
              onClick={() => {
                setShowSuccessModal(false);
                setShowValidate(false);
                setParams({
                  name: '',
                  email: '',
                  phone: '',
                  company: '',
                });
              }}
            >
              Done
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ConsultForm;