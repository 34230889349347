import React  from 'react';
import Image from "@components/Image";

const Section2 = ({ info }) => {
  return (
    <div className="px-[20px] md:px-[40px] lg:px-[360px]">
      <p className="text-center font-bold text-[24px] md:text-[38px]">Benefits of Joining the Digital BA</p>
      <div className="relative">
        <div className="absolute -left-[80px] md:-left-[100px] -bottom-[50px] md:-bottom-[100px] w-[160px] md:w-[200px]">
          <Image data={info.blueCone} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-12 relative">
          <div>
            {info.cards.slice(0, info.cards.length / 2).map((card) => (
              <div className="p-4 lg:p-8 shadow bg-white rounded-[8px] mb-8 last:mb-0">
                <Image className="w-[40px]" data={card.icon}/>
                <p className="font-medium">{card.title}</p>
                <p className="text-[12px] leading-normal">{card.content}</p>
              </div>
            ))}
          </div>
          <div className="md:pt-[40px]">
            {info.cards.slice(info.cards.length / 2).map((card) => (
              <div className="p-4 lg:p-8 shadow bg-white rounded-[8px] mb-8 last:mb-0">
                <Image className="w-[40px]" data={card.icon}/>
                <p className="font-medium">{card.title}</p>
                <p className="text-[12px] leading-normal">{card.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute -right-[20px] md:-right-[28px] top-[10px] md:top-[20px] w-[60px] md:w-[100px]">
          <Image data={info.pinkCone} />
        </div>
      </div>
      <div className="relative md:pl-[150px] pt-[80px] md:pt-[20px]">
        <p className="font-semibold md:w-[70%] text-[16px] leading-normal">{info.experience?.description}</p>
        <div className="grid grid-cols-2 md:grid-cols-4 items-center justify-between gap-y-4 px-[10px] md:px-0">
          {info.experience?.customers?.map(({ logo }) => (
            <div className="even:text-right md:even:text-left">
              <div className="w-[120px] md:w-[100px] inline-block">
                <Image data={logo} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default Section2;